<template>
  <main class="v-content" id="home" data-booted="true">
    <div class="v-content__wrap">
      <div id="home2">
        <section id="feed">
          <div class="container">
            <v-row>
              <v-col
                  class="d-flex child-flex"
                  cols="12"
              >
                <v-img     
                      max-height="280"
                      height="300"
                       class="white--text"
                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                       src="../assets/img/seminare.jpg"
                       aspect-ratio="1.7">
                  <div class="col-md-6 offset-md-5 col-9 title-col semi"><h1 class="display-1 font-weight-light">
                   „Erfolg besteht darin, dass man genau die Fähigkeiten hat, die im Moment gefragt sind.“
                  </h1>
                   <div class="subheading img text-uppercase mb-4">Henry Ford</div>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </div>
        </section>
        <section class="container" id="about"><h2 class="subheading text-uppercase font-weight-medium mb-3">Seminare und Workshops</h2>
          <p>
            Um genau an Ihrem Bedarf einen Workshop oder ein Seminar entwickeln zu können,
             gestalten wir die Inhalte mit Ihnen individuell nach Absprache für die jeweilige Zielgruppe. 
             Wir berücksichtigen bereits vorhandene Kompetenzen der Teilnehmer*innen und vermitteln Inhalte so,
              dass diese von den Teilnehmern*innen sofort in der Praxis umsetzbar sind. Dabei liegen unsere 
              Schwerpunkte auf folgenden Themen:</p>
          <p>
          <ul>
          <li>Führungskompetenz ausbauen und entwickeln</li>
          <li>Zielgerichteter Umgang mit Konflikten</li>
          <li>Resilienztraining</li>
          <li>Lösungsorientierte Kommunikationsstrategien</li>
          <li>Präsenztraining</li>
          <li>Persönliche Standortbestimmung</li>
          <li>Burn-Out Prävention/Stressmanagement</li>
          <li>Bedienungsanleitung für Homo sapiens (kann auch als Erlebnisworkshop gebucht werden)  </li>
          </ul>
          </p>
          <p>Gern entwickeln wir mit Ihnen Seminare und Workshops speziell nach Ihren Bedürfnissen. Egal ob im Businesskontext, für Vereine oder private Veranstaltungen.</p>
             <p>
            Wenn Sie eine Frage haben, eine Anfrage stellen oder einen Termin
            vereinbaren möchten, rufen Sie gern an oder nutzen Sie das
            <router-link to="/contact"
              ><span style="text-decoration: underline"
                >Kontaktformular.</span
              ></router-link
            >
            Ich freue mich auf Sie.
          </p></section>
      </div>
    </div>
  </main>
</template>

<script>
import countapi from 'countapi-js';
export default {
  name: "GallerieMia",
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    countapi.update('cct-handschuh.de', 'seminare', 1);
    this.toTop();
  },
};
</script>

<style scoped lang="scss">
@media (max-width: 400px) {
  .subheading.img.text-uppercase {
    padding-bottom: 10px;
    font-size: 13px !important;
  }
}
.title-col {
  margin-top: 30px;
}
.semi{
  margin-top: 50px;
  @media screen and (max-width: 762px){
    margin-top: 86px;
  }
}
</style>