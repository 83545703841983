<template>
  <main
    class="v-content"
    id="home"
    data-booted="true"
  >
    <div class="v-content__wrap">
      <div id="home2">
        <section id="feed">
          <div class="container">
            <v-row>
              <v-col class="d-flex child-flex" cols="12">
                <v-img
                  max-height="280"
                  height="300"
                  class="white--text"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  src="../assets/img/coaching.jpg"
                  aspect-ratio="1.7"
                >
                  <div class="col-md-6 offset-md-5 col-9 title-col">
                    <h1 class="display-1 font-weight-light">
                        „Um klar zu sehen, genügt oft eine Veränderung der
                      Blickrichtung“
                    </h1>
                    <div class="subheading img text-uppercase mb-4">
                      Antoine de Saint-Exupéry
                    </div>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </div>
        </section>
        <section class="container" id="about">
          <h2 class="subheading text-uppercase font-weight-medium mb-3">
            Coaching
          </h2>
          <p>
            Der Begriff Coaching wird in den letzten Jahren, insbesondere in der
            modernen Medienlandschaft, zunehmend inflationär verwendet.
            Professionelles Coaching, ob im Businesskontext oder im privaten
            Bereich, ist in unserem Verständnis eine ziel- und
            lösungsorientierte Form der Interaktion zwischen uns als Coaches und
            Ihnen. Hierbei geht es darum, für Ihre jeweilige Situation oder
            Herausforderung, nützliche Denk-, Empfindungs- und damit
            Verhaltensmuster zu entwickeln und zu verinnerlichen. Dabei geht es
            ausschließlich um Sie. Denn Sie sind die Expertin/der Experte für
            die Inhalte, die Ihnen wichtig sind und mit denen Sie sich
            beschäftigen möchten. Wir stehen als Experten für den
            Coaching-Prozess an Ihrer Seite.
          </p>
          <p>
            Wir gestalten diesen Prozess für Sie transparent und streben eine
            maximale Nachhaltigkeit an. Wir fokussieren gemeinsam auf Lösungen
            oder Ziele und konstruieren mit Ihnen die passende Strategie und die
            notwendigen Schritte. Dabei werden mögliche Risiken, die Ihre
            Zielerreichung beeinflussen können, berücksichtigt und in den
            Lösungsprozess integriert
          </p>
          <p>
            Wenn Sie eine Frage haben, eine Anfrage stellen oder einen Termin
            vereinbaren möchten, rufen Sie gern an oder nutzen Sie das
            <router-link to="/contact"
              ><span style="text-decoration: underline"
                >Kontaktformular.</span
              ></router-link
            >
            Ich freue mich auf Sie.
          </p>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import countapi from 'countapi-js';
export default {
  name: "PeopleGalerie",
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    this.toTop();
    countapi.update('cct-handschuh.de', 'coaching', 1);
  },
};
</script>

<style scoped lang="scss">
.title-col {
  margin-top: 30px;
}
@media (max-width: 400px) {
  .subheading.img.text-uppercase {
    padding-bottom: 10px;
    font-size: 13px !important;
  }
}
</style>