<template>
  <main
    class="v-content"
    id="home"
    data-booted="true"
    
  > 
    <div class="v-content__wrap home-p">
      <v-container mx-auto>
        <v-row> <v-col
        class="d-flex child-flex" 
            :cols="colImg"
          ></v-col>
          <v-col
          class="d-flex child-flex"  :cols="colTxt">
          <section >
         <h2 class="subheading text-uppercase font-weight-medium">
                Das Team
              </h2></section></v-col></v-row>
        <v-row>
          <v-col class="d-flex child-flex" :cols="colImg">
           
      <v-img v-if="!$mqAliases.touch"
                :height="heightImg +10"
                class="white--text"
                src="../assets/img/eHanderschuh.jpg"
                aspect-ratio="1.7"
                 max-width="340"
              >
              </v-img>
      <v-img v-if="$mqAliases.touch"
                contain
                :height="heightImg"
                class="white--text"
                src="../assets/img/eHanderschuh.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
            
          </v-col>
          <v-col class="d-flex child-flex" :cols="colTxt">
            <section>
              <p>
               <span style="font-weight: bold;"> Erik Handschuh </span> zertifizierter Business-Coach, systemischer Coach und NLP-Lehrtrainer. 
                Beim Deutschen Verband für Coaching und Training ist er als Gutachter und Mitglied der
                 Zertifizierungskommission tätig und nimmt Prüfungen für Coaches und Trainer ab. 
                 Er hat mehr als 20 Jahre Erfahrung als Führungskraft, davon 18 Jahre Verantwortung 
                 als Abteilungsleiter mit bis zu 100 Mitarbeitenden und Führungskräften in 
                 verschiedensten Tätigkeitsprofilen.
              </p>
              <p>
                Während dieser Zeit hat er klassische Methoden aus der Kommunikationspsychologie,
                 dem Konfliktmanagement und Coaching-Tools in seine Führungsarbeit integriert.
                  Mit diesen Erfahrungen stellt er Führungskräften unterschiedlicher Hierarchieebenen
                   und Mitarbeitenden ein an der Praxis orientiertes Repertoire von Tools zur Verfügung,
                    die er entsprechend den Themenschwerpunkten alltagstauglich sowie praxiserprobt präsentiert 
                    und vermittelt.
              </p>
              <p>
                „Wertschätzend und gleichzeitig im Sinne des 
                Unternehmenserfolges zielorientiert führen und entwickeln, 
                Führungskraft und gleichzeitig Coach seiner Mitarbeiter*innen zu sein, 
                sind aus meiner Erfahrung essentielle Faktoren für nachhaltigen Unternehmenserfolg.“
              </p>
              <p>
      Unter diesem Motto arbeitet er 
      sowohl als Inhaber bei cct – creative coaching & training, als
       auch bei einem der größten Wasserversorger Europas, um weiterhin den
        Kontakt zur Praxis zu halten und damit individuell auf den Bedarf der Kunden eingehen zu können.
              </p>
              <p>Gemeinsam mit Dr. Carsten Brunner führt er bei <a href="http://berlinvisionaere.de/" target="_blank"><span style="text-decoration:underline;"> Berlin-Visionäre</span></a> NLP Ausblidungen durch.</p>
              <div class="dvct">
                <div>
                  <v-img
                  height="80"
                  width="100"
                  contain
                  src="../assets/img/Trainer_DVCT.jpg"
                  aspect-ratio="1.7"
                ></v-img>
                </div>
                <div>
                <v-img
                  height="80"
                  width="100"
                  contain
                  src="../assets/img/dvct.jpg"
                  aspect-ratio="1.7"
                ></v-img>
                </div>
                <div class="fww">
                <v-img
                  height="100"
                  width="150"
                  contain
                  src="../assets/img/FWW_Siegel.png"
                  aspect-ratio="1.7"
                ></v-img>
              </div>
              </div>
            </section>
          </v-col>
        </v-row>
          <v-row>
          <v-col class="d-flex child-flex" :cols="colImg">
           
      <v-img v-if="!$mqAliases.touch"
                :height="heightImg"
                contain
                class="white--text"
                src="../assets/img/Carsten_b.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
      <v-img v-if="$mqAliases.touch"
                contain
                :height="heightImg"
                class="white--text"
                src="../assets/img/Carsten_b.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
            
          </v-col>
          <v-col class="d-flex child-flex" :cols="colTxt">
            <section>
              <p>
               <span style="font-weight: bold;"> Dr. Carsten Brunner </span> ist selbständiger Rechtsanwalt, NLP Lehrtrainer, Coach und zertifizierter Mediator.
              <p>
                Er ist seit geraumer Zeit, neben seiner hauptberuflichen Tätigkeit, auch in den Bereichen Kommunikationstraining, NLP-Ausbildungen, Coaching und Mediation tätig, stets nach seiner Leitdevise „Geht nicht, gibt’s nicht!"
              </p>
              <p>Im Rahmen seiner verschiedenen Professionen war 
              Dr. Brunner als Dozent sowohl an der FU Berlin, als auch bei verschiedenen Institutionen der Erwachsenenbildung tätig, dies seit nunmehr über 28 Jahren.</p><p>Dr. Carsten Brunner führt als Trainer, zusammen mit Erik Handschuh und dem Team der <a href="http://berlinvisionaere.de/" target="_blank"><span style="text-decoration:underline;"> Berlin-Visionäre</span></a>, regelmäßig NLP-Ausbildungen in Berlin durch.
             </p>
            </section>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex child-flex" :cols="colImg">
      <v-img v-if="!$mqAliases.touch"
                :height="heightImg"
                contain
                class="white--text"
                src="../assets/img/birgit_thedens.jpg"
                aspect-ratio="1.7"
                 max-width="360"
              >
              </v-img>
 <v-img v-if="$mqAliases.touch"
                contain
                :height="heightImg"
                class="white--text"
                src="../assets/img/birgit_thedens.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
           
          </v-col>
          <v-col class="d-flex child-flex" :cols="colTxt">
            <section>
              <p>
                <span style="font-weight: bold;">Birgit Thedens</span>  ist Business-Coach, Trainerin/eTrainerin (dvct zertifiziert) und NLP-Lehrtrainerin aus Leidenschaft. Seit 2014 führt sie in Hamburg ein eigenes Ausbildungsinstitut für Coaching und NLP. Zusätzlich engagiert sich die Vorstandsvorsitzende des Deutschen Verbands für Coaching und Training  für den Berufsstand von Coaches und (e)Trainer*innen.
              </p>
              <p>
               Die Begleitung von Unternehmen und Einzelpersonen in Veränderungsprozessen, die Möglichkeit, gemeinsam neue Ziele, Strategien und Verhaltensweisen zu erarbeiten, beschreibt sie als eine immer wieder faszinierende und sie begeisternde Herausforderung.
              </p>
              <p>
                Die Betriebswirtin und Integrative Gestalttherapeutin/Heilpraktikerin f. Psychotherapie bietet fast 30 Jahre Erfahrung in allen Rollen unternehmerischen Denken und Handelns. Seit 1993 arbeitet sie als
              </p>
              <p>
               <ul>
                 <li>Trainerin für KMU und öffentliche Einrichtungen</li>
                  <li>Unternehmensberaterin Coach für Unternehmer, Führungskräfte, Mitarbeiter und Privatpersonen</li>
                  <li>Therapeutin (HPG) und Prozessbegleiterin in eigener Praxis</li>
                  <li>Dozentin für Wirtschafts- und psychologisch/soziologisch basierte Themen an verschiedenen Bildungseinrichtungen</li>
               </ul>
              </p>
              <p>Ihr größtes Anliegen: „Unternehmen dabei zu unterstützen, ein neues Führungsbewusstsein und eine neue Führungskultur zu etablieren. In der Personalentwicklung nicht nur ein leeres Schlagwort ist, sondern als wirklicher Auftrag verstanden wird. Führungskräfte zu befähigen, ihre Teams und Mitarbeiter*innen zeitgleich zu fördern und zu fordern, in dem sie motivierende Arbeitsbedingungen schaffen, Lern- und Entwicklungsfelder erkennen und wertschätzenden Feedback auf Augenhöhe geben. Soziale Kompetenzen wie auch emotionale Intelligenz und ein coachender Führungsstil sind neue, wichtige Skills. Eine auf Zielen und Wertschätzung basierende Führung zeigt sehr schnell positive Auswirkungen auf die Ergebnisse – bei gleichzeitig gesteigerter Arbeitszufriedenheit und besserem Betriebsklima – zwei wichtige Faktoren im Wettbewerb um qualifizierte Mitarbeiter*innen und Führungskräfte.“</p>
           <p>Gemäß ihrem Motto „…gönne dir immer die beste aller Möglichkeiten…“, macht sie „Positive Führung“ erleb- und erlernbar.</p>
               <div class="dvct">
                <div>
                  <v-img
                  height="80"
                  width="100"
                  contain
                  src="../assets/img/Trainer_DVCT.jpg"
                  aspect-ratio="1.7"
                  
                ></v-img>
                </div>
                <div>
                <v-img
                  height="80"
                  width="100"
                  contain
                  src="../assets/img/dvct.jpg"
                  aspect-ratio="1.7"
                ></v-img>
                </div>
                <div>
                <v-img
                  height="80"
                  width="100"
                  contain
                  src="../assets/img/eTrainer.png"
                  aspect-ratio="1.7"
                ></v-img>
              </div>
              </div>
            </section>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex child-flex" :cols="colImg">
        
      <v-img v-if="!$mqAliases.touch"
                :height="heightImg"
                contain
                class="white--text"
                src="../assets/img/Heiner_Diepenhorst.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
 <v-img v-if="$mqAliases.touch"
                contain
                :height="heightImg"
                class="white--text"
                src="../assets/img/Heiner_Diepenhorst.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
        
          </v-col>
          <v-col class="d-flex child-flex" :cols="colTxt">
            <section>
              <p>
                Als Kommunikationswissenschaftler hat <span style="font-weight: bold;">Heiner Diepenhorst</span> Jahre lang im Volkswagen Konzern gearbeitet und war dort Pressesprecher sowie Projektleiter. Heute ist er Coach, Trainer, Prozessmoderator und NLP-Master.
              </p>
              <p>
               Aufgrund dieser Erfahrungen, setzt er sowohl Unternehmen als auch die Menschen in Szene und leitet Veränderungen systhemverträglich und zielsicher in die Wege.
              </p>
              <p>
             Dabei geht es ihm um alle vorhandenen unternehmerischen und menschlichen Potentiale, auf die er in seinen Businesscoachings, Strategie-Workshops, Kommunikations- und Kreativitätstrainings focussiert.
              </p>
            
              <p>„Egal ob einzelne Personen oder Gruppen – wichtig ist es, einen Raum zu schaffen, in dem sich die Beteiligten sicher, autark und geführt zugleich fühlen. So kann auch das bisher nicht Ausgesprochene zu Wort kommen, wodurch die Ziele des Klienten oder der Firma wesentlich leichter zu erreichen sind.“</p>

           <p>Als Partner bei cct – creative coaching & training – stellt Heiner Diepenhorst seine Erfahrungen aus Wissenschaft und Wirtschaft in den Dienst der Auftraggeber. Mal beobachtend, mal handelnd, mal provokant und mal verständnisvoll. Im Mittelpunkt seiner Arbeit steht der Mensch.</p>
            </section>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex child-flex f-column" :cols="colImg">
           
      <v-img v-if="!$mqAliases.touch"
                :height="heightImg"
               class="white--text"
                src="../assets/img/Steffen_Meißner.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
 <v-img v-if="$mqAliases.touch"
                :height="heightImg"
                class="white--text"
                src="../assets/img/Steffen_Meißner.jpg"
                aspect-ratio="1.7"
              >
              </v-img>
          </v-col>
          <v-col class="d-flex child-flex" :cols="colTxt">
            <section>
              <p>
                <span style="font-weight: bold;">Steffen Meißner</span> ist selbstständiger Skipper und Ausbilder für sämtliche See- und Funkscheine.
              </p>
              <p>
               Er hat mittlerweile über 35 Jahre Erfahrung in der Seefahrt.
              </p>
              <p>
            Gemeinsam mit einem weiteren Mitglied der Stammcrew ist er bei unseren Erlebnisveranstaltungen verantwortlich für die Yacht, die Auswahl des Revieres und die Sicherheit an Bord.</p>
            <p>Frei seinem Motto „Teamgeist ist Grundlage für Sicherheit und erfülltes Erleben auf dem Wasser“, lässt er während der Törns, Interessierte gern an seinen Kenntnissen teilhaben.</p>
            </section>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </main>
</template>

<script>
import countapi from 'countapi-js';
export default {
  name: "TeamAll",
  data() {
    return {
      colImg: 4,
      colTxt: 8,
      heightImg: 450,
      cardHeight: 400,
    };
  },
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    countapi.update('cct-handschuh.de', 'das-team', 1);
    this.toTop();
    if (this.$mqAliases.mobile) {
      this.colImg = 12;
      this.colTxt = 12;
      this.heightImg = 310;
      this.cardHeight = 360;
    }
  },
};
</script>
<style scoped lang="scss">
section{
  padding-left: 10px;
}
.f-column {
  width: 100%;
  display: flex;
}
.heading-t {
  margin-top: 10px;
  margin-left: 10px;
}
.dvct {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  .fww {
    position: relative;
    top: 7px;
    @media screen and (max-width: 720px) {
      margin-left: -30px;
    }
  }
}
p {
  line-height: 1.8;
}
.home-p {
  @media screen and (min-width: 1023px) and (max-width: 1200px) {
    margin-top: 20px;
  }
}
@media (max-width: 400px) {
  .subheading.img.text-uppercase {
    padding-bottom: 10px;
    font-size: 13px !important;
  }
}
.row {
margin-bottom: 30px;
}
</style>