<template>
  <v-toolbar color="rgb(245, 245, 245)" flat height="auto" min-height="64">
    <v-app-bar-nav-icon
      @click.stop="openDrawer"
      v-if="$mqAliases.touch"
    ></v-app-bar-nav-icon>
    <h1 v-if="$mqAliases.touch" class="shadow">
      <router-link to="/">
        <span style="color: #4d5965"> cct</span>
      </router-link>
    </h1>
    <div class="container navi py-0" v-if="!$mqAliases.touch">
      <div class="row-navi">
        <router-link
          to="/"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default btnnav"
        >
          <span class="v-btn__content shadow">cct</span>
        </router-link>
        <router-link
          to="/"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default"
        >
          <span class="v-btn__content"> Home</span>
        </router-link>
        <router-link
          to="/coaching"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default"
        >
          <span class="v-btn__content"> Coaching </span>
        </router-link>
        <router-link
          to="/team-entwicklung"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default"
        >
          <span class="v-btn__content"> Teamentwicklung</span>
        </router-link>
        <router-link
          to="/seminare"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default"
        >
          <span class="v-btn__content"> Seminare und Workshops</span>
        </router-link>
        <router-link
          to="/erlebnisseminare"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default"
        >
          <span class="v-btn__content">Erlebniswokshop</span>
        </router-link>
        <router-link
          to="/burn-out"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default"
        >
          <span class="v-btn__content">Burn-out</span>
        </router-link>
        <router-link
          to="/das-team"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default"
        >
          <span class="v-btn__content">Team</span>
        </router-link>

        <router-link
          to="/contact"
          class="hidden-sm-and-down v-btn v-btn--flat v-btn--text theme--light v-size--default"
        >
          <span class="v-btn__content">Kontakt</span>
        </router-link>
      </div>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  name: "FotoHeader",
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    openDrawer() {
      this.drawer = !this.drawer;
      this.$bus.emit("open-drawer", this.drawer);
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  created() {
    this.$bus.on("close-drawer", this.openDrawer);
  },
};
</script>

<style lang="scss">
.v-toolbar {
  flex-grow: 0 !important;
}
.home {
  flex-grow: 1;
}
#home2 {
  #feed {
    @media screen and (min-width: 1023px) and (max-width: 1200px) {
      margin-top: 30px !important;
    }
  }
}
.Contact {
  @media screen and (min-width: 1023px) and (max-width: 1200px) {
    margin-top: 30px !important;
  }
}
.v-toolbar__content {
  height: unset;
}
a {
  color: black;
  text-decoration: none;
  &:visited {
    color: black !important;
  }
  &:focus {
    color: black;
  }
}
@media (max-width: 760px) {
  .v-application .display-1 {
    padding-bottom: 10px;
    font-size: 20px !important;
    line-height: 1.4;
    font-weight: 400 !important;
    margin-top: -10px !important;
  }
}
.container.navi {
  max-width: unset;
  .row-navi {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    margin-top: 11px;
    justify-content: flex-start;
    .v-btn:not(.v-btn--round).v-size--default {
      padding: 0px 12px;
    }
  }
}

:root {
  --opacity: 0.2;
}
.shadow {
  font-weight: 300;
  font-size: 36px;
  text-shadow: 5px 0 var(--yellow);
  color: #4d5965;
  text-align: center;
  text-transform: lowercase;
}
.btnnav.router-link-exact-active.router-link-active:hover{
    background-color: transparent !important;
  } 
</style>