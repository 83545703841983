<template>
  <div class="Booking">
    <contact-booking></contact-booking>
  </div>
</template>
<script>
import ContactBooking from "@/components/BookingForm";

export default {
  name: "About",
  components: {
    ContactBooking,
  },
};
</script>