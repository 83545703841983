<template>
  <div class="data">
    <data-protection></data-protection>
  </div>
  </template>
<script>
import DataProtection from '@/components/DataProtection.vue'


export default {
  name: 'About',
  components: {
    DataProtection,
  }
}
</script>