<template>
  <div class="burn-out-view">
    <burn-out></burn-out>
  </div>
  </template>
<script>
import BurnOut from '@/components/BurnOut.vue'

export default {
  name: 'BurnOutView',
  components: {
    BurnOut,
  }
}
</script>