<template>
  <v-navigation-drawer v-model="drawer" fixed top temporary>
    <v-list two-line nav dense>
      <v-list-item-group active-class="deep-purple--text text--accent-4">
        <v-list-item>
          <router-link to="/"
            ><v-list-item-title><span  style="color: black; font-size: 15px;">Home</span></v-list-item-title></router-link
          >
        </v-list-item>

        <v-list-item>
          <router-link to="/coaching"
            ><v-list-item-title><span  style="color: black; font-size: 15px;">Coaching</span></v-list-item-title></router-link
          >
        </v-list-item>

        <v-list-item>
          <router-link to="/team-entwicklung"
            ><v-list-item-title
              ><span  style="color: black; font-size: 15px;">Team-Entwicklung</span></v-list-item-title
            ></router-link
          >
        </v-list-item>
        <v-list-item>
          <router-link to="/seminare"
            ><v-list-item-title
              ><span  style="color: black; font-size: 15px;">Seminare und Workshops</span></v-list-item-title
            ></router-link
          >
        </v-list-item>
        <v-list-item>
          <router-link to="/erlebnisseminare"
            ><v-list-item-title
              ><span  style="color: black; font-size: 15px;">Erlebnisworkshop</span></v-list-item-title
            ></router-link
          >
        </v-list-item>
        <v-list-item>
          <router-link to="/burn-out">
            <v-list-item-title><span  style="color: black; font-size: 15px;">Burn-out</span></v-list-item-title></router-link
          >
        </v-list-item>
        <v-list-item>
          <router-link to="/das-team"
            ><v-list-item-title><span  style="color: black; font-size: 15px;">Team</span></v-list-item-title></router-link
          >
        </v-list-item>
        <v-list-item>
          <router-link to="/contact">
            <v-list-item-title><span  style="color: black; font-size: 15px;">Kontakt</span></v-list-item-title></router-link
          >
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "MobileNavi",
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    openDrawer(draw) {
      this.drawer = draw;
    },
  },
  watch: {
    drawer: {
      handler: function (val) {
        if (val === false) {
          this.$bus.emit("close-drawer", val);
        }
      },
      deep: true,
    },
  },
  created() {
    this.$bus.on("open-drawer", this.openDrawer);
  },
};
</script>

<style scoped lang="scss">
a{
  color: black im !important;
  text-decoration: none;
    width: 100%;
    height: 50px;
    display: flex;

   &:visited{
     color: black !important;
   }
}
</style>
