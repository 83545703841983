<template>
  <main class="v-content" id="home" data-booted="true">
    <div class="v-content__wrap">
      <div class="container contact-booking">
        <h2 class="subheading text-uppercase font-weight-medium mb-3">
          Möchten Sie eine Buchungsanfrage stellen ?
        </h2>
        <h4 class="subheading text-uppercase font-weight-medium mb-3">
          Dann schicken Sie mir gern eine Nachricht
        </h4>
        <form action="https://formspree.io/f/mbjpzgre" method="POST">
          <input
            type="text"
            class="booking"
            value="Buchungsanfrage"
            name="Betreff"
            style="
              width: 300px;
              left: -15px;
              position: relative;
              margin-bottom: 10px;
              padding: 5px;
              text-align: center;
            "
          />
          <div class="row">
            <div class="col-75">
              <label>
                E-mail:
                <input type="text" class="email2" @blur="trimSpaces" name="_replyto" />
              </label>
            </div>
            <div class="col-75">
              <label>
                Nachricht:
                <textarea name="message"></textarea>
              </label>
            </div>
          </div>
          <div class="row">
            <button type="submit">Senden</button>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "ContactFrom",
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    trimSpaces: function () {
      document.querySelector(".email2").value = document
        .querySelector(".email2")
        .value.trim();
    },
  },
  created() {
    this.toTop();
  },
};
</script>

<style lang="scss" scoped>
h2,
h4 {
  width: 100%;
  text-align: center;
}
.contact-booking {
  margin-top: 20px;
}
form {
  input,
  textarea {
    border: 1px solid black;
  }

  textarea {
    min-height: 200px;
  }
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

.container {
  min-height: 700px;
  border-radius: 5px;
  padding: 20px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 762px) {
    min-height: 900px;
  }
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
</style>