<template>
  <div class="Team-e">
    <team-all></team-all>
  </div>
</template>

<script>
import TeamAll from '@/components/TeamAll.vue'

export default {
  name: "TeamE",
  components: {
    TeamAll,

  }
}
</script>

<style scoped>

</style>