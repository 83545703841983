<template>
<div class="Galerie">
  <gallerie-mia></gallerie-mia>
</div>
</template>

<script>
import GallerieMia from '@/components/GallerieMia.vue'
export default {
  name: "Gallerie",
  components:{ GallerieMia},
}
</script>

<style scoped>

</style>