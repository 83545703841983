<template>
  <main
    class="v-content"
    id="home"
    data-booted="true"
  >
    <div class="v-content__wrap home-p">
      <v-container mx-auto>
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="4">
            <v-img
              height="585"
              class="white--text"
              src="../assets/img/cct-title-f.jpg"
              aspect-ratio="5"
              position="center top"
            >
              <div class="col-md-12 col-12 title-col">
                <h3 class="heading-t display-1 font-weight-light">
                  creative coaching & training
                </h3>
                <div class="subheading img text-uppercase pl-2 mb-4">
                  Erik Handschuh
                </div>
              </div>
            </v-img>
          </v-col>
          <v-col cols="12" sm="12" md="8" lg="8" xl="8">
            <section class="container" id="about">
              <h2 class="subheading text-uppercase font-weight-medium mb-3">
                Herzlich Willkommen
                <span class="cct-heading">bei creative coaching & training</span>
              </h2>
              <p>
                Vielen Dank, dass Sie uns besuchen. Diese Seite soll Ihnen einen
                ersten Eindruck von unserem Team vermitteln und Sie einladen,
                sich über unser Leistungsportfolio zu informieren.
              </p>
               <p>
                Mit einem sorgfälltig ausgewählten Team von zertifizierten
                Coaches, Trainern und Business-Partnern, stehen wir Ihnen als
                Unternehmen, Firma oder Privatperson für unterschiedlichste
                Themen und Schwerpunkte zur Verfügung.
              </p>
              <p>
                Alle Coaches und Trainer im Team von creative coaching &
                training haben eigene, langjährige Erfahrungen in Unternehmen
                als Führungskraft, in der Personalentwicklung und im Marketing
                gemacht und arbeiten daher maximal praxisorientiert.
              </p>
              <p>
                Sollte Ihnen der persönliche Kontakt angenehmer sein als der
                Klick durch diese Seite oder Sie haben Fragen, die hier nicht
                beantwortet werden, rufen Sie gern unverbindlich an.
              </p>
              <p>Das Team und ich freuen uns auf Sie</p>
              <p>
                <v-img
                  height="100"
                  width="150"
                  contain
                  src="../assets/img/sign.png"
                  aspect-ratio="1.7"
                ></v-img>
              </p>
              <p>Erik Handschuh</p>
              <div class="seal">
                <div>
                  <v-img
                    height="80"
                    width="100"
                    contain
                    src="../assets/img/Trainer_DVCT.jpg"
                    aspect-ratio="1.7"
                  ></v-img>
                </div>
                <div>
                  <v-img
                    height="80"
                    width="100"
                    contain
                    src="../assets/img/dvct.jpg"
                    aspect-ratio="1.7"
                  ></v-img>
                </div>
                <div class="fww">
                  <v-img
                    height="100"
                    width="150"
                    contain
                    src="../assets/img/FWW_Siegel.png"
                    aspect-ratio="1.7"
                  ></v-img>
                </div>
              </div>
            </section>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </main>
</template>

<script>
import countapi from 'countapi-js';
export default {
  name: "HelloWorld",
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    countapi.update('cct-handschuh.de','home',  1);
    this.toTop();
  },
};
</script>
<style scoped lang="scss">
.mob{
  width: 100%;
    margin-top: 294px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}
.cct-heading {
  text-transform: lowercase;
}
.title-col {
  position: relative;
  top: 305px;
  @media screen and (max-width: 959px) {
  top: 480px}
    @media screen and (max-width: 490px ) {
  top: 330px}
}
.seal {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  .fww {
    margin-top: -16px;
    @media screen and (max-width: 762px) {
      margin-left: -30px;
      margin-top: unset;
    }
  }
}
.heading-t {
  margin-left: 10px;
  @media screen and (max-width: 900px) {
    font-size: 24px !important;
    max-width: 80%;
    top:300px;
  }
}
p {
  line-height: 1.8;
}
.home-p {
  @media screen and (min-width: 1023px) and (max-width: 1200px) {
    margin-top: 30px;
  }
}
@media (max-width: 762px) {
  .subheading.img.text-uppercase {
    padding-bottom: 10px;
    font-size: 13px !important;
    margin-top: -5px;
  }
}
@media (max-width: 350px) {
  .subheading.img.text-uppercase {
    padding-bottom: 10px;
    font-size: 13px !important;
    position: unset;
    margin-top: -5px;
  }
}
</style>