<template>
  <div class="Coaching-vue">
    <people-galerie></people-galerie>
  </div>
</template>

<script>
import PeopleGalerie from '@/components/PeopleGalerie.vue'

export default {
  name: "CoachingVue",
  components: {
    PeopleGalerie,

  }
}
</script>

<style scoped>

</style>