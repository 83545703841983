<template>
  <div class="imprint">
    <impressum></impressum>
  </div>
  </template>
<script>
import Impressum from '@/components/Impressum.vue'

export default {
  name: 'About',
  components: {
    Impressum,
  }
}
</script>