<template>
  <div class="about">
    <about-me></about-me>
  </div>
  </template>
<script>
import AboutMe from '@/components/AboutMe.vue'

export default {
  name: 'About',
  components: {
    AboutMe,
  }
}
</script>