<template>
  <main class="v-content" id="home" data-booted="true">
    <div class="v-content__wrap">
      <div id="home2">
    
        <section id="feed">
          <div class="container">
            <v-row>
              <v-col
                  class="d-flex child-flex"
                  cols="12"
                  
              >
              <div class="img-b">
                <v-img  
                dark 
                      max-height="300"
                      height="320"
                       class="white--text"
                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                       src="../assets/img/team-e.jpg"
                       aspect-ratio="1.7">
                  <div class="col-md-6 offset-md-6 col-9 title-col" style="filter:unset"><h1 class="display-1 font-weight-light">
                   „Zusammenkommen ist ein Beginn, zusammenbleiben ist ein Fortschritt, zusammenarbeiten ist ein Erfolg.“
                  </h1>
                   <div class="subheading img text-uppercase mb-4">Henry Ford</div>
                  </div>
                </v-img>
              </div>
              </v-col>
            </v-row>
          </div>
        </section>
        <section class="container" id="about"><h2 class="subheading text-uppercase font-weight-medium mb-3">Teamentwicklung</h2>
          <p> Damit ein Team bei der ihm gestellte Aufgabe optimal zusammenarbeiten kann, müssen mehrere Faktoren erfüllt sein.
          </p>
          <p>
          <ul>
          <li>Fachliche Kompetenz für die zu erfüllende Aufgabe</li>
          <li>Teammitglieder ergänzen sich in ihren persönlichen und fachlichen Kompetenzen</li>
          <li>Gegenseitige Akzeptanz und Wertschätzung</li>
          <li>Einsatz an der jeweils optimalen Stelle innerhalb des Teams</li>
          <li>Rollen-, Themen- und Kompetenzklarheit</li>
          <li>Motivation, sich für das Thema und das Team zu engagieren usw.</li>
          </ul>
          </p>
          <p>
           Eine Optimalbesetzung ist jedoch in vielen Fällen nicht möglich, da man sich bei der Zusammensetzung eines Teams an unternehmenspolitischen und marktrelevanten Rahmenbedingungen orientieren muss.</p>
          <p>In Zusammenarbeit mit der Führungskraft, ermitteln wir die Potentiale im Team, erarbeiten Maßnahmen zur gezielten Teamentwicklung und gestalten gemeinsam die nachhaltige Umsetzung</p>
          <p>Wenn Sie eine Frage haben, eine Anfrage stellen oder einen Termin vereinbaren möchten, rufen Sie gern an oder nutzen Sie das <router-link to="/contact"><span style="text-decoration: underline;">Kontaktformular.</span></router-link> 
Ich freue mich auf Sie.</p>
           </section>
      </div>
    </div>
  </main>
</template>

<script>
import countapi from 'countapi-js';

export default {
  name: "PeopleGalerie",
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    countapi.update('cct-handschuh.de', 'team-entwicklung', 1);
    this.toTop();
  },
};
</script>

<style scoped lang="scss">
.title-col {
  margin-top: 30px;
}
.img-b {
  .display-1{
  margin-left: -5px;
  margin-top:-20px;}
}
@media (max-width: 400px) {
  .subheading.img.text-uppercase {
    padding-bottom: 10px;
    font-size: 13px !important;
  }
}
</style>