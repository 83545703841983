import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Imprint from '../views/Imprint.vue'
import People from '../views/CoachingVue.vue'
import About from '../views/About.vue'
import Team from '../views/TeamDev.vue'
import Contact from '../views/Contact.vue'
import Data from '../views/Data.vue'
import BurnOut from '../views/BurnOutView.vue'
import Booking from '../views/Booking.vue'
import TeamE from '../views/TeamE.vue'
import Seminare from "@/views/Seminare.vue";
import Statistics from '../components/Statistics.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import countapi from 'countapi-js';

Vue.use(VueAxios, axios)

Vue.use(countapi);
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home

    },
    {
        path: '/erlebnisseminare',
        name: 'erlebnisseminare',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: About
    }, {
        path: '/seminare',
        name: 'seminare',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Seminare
    },
    {
        path: '/contact',
        name: 'Contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Contact
    },
    {
        path: '/burn-out',
        name: 'burnOut',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: BurnOut
    },
    {
        path: '/coaching',
        name: 'coaching',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: People
    },
    {
        path: '/team-entwicklung',
        name: 'team-entwicklung',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Team


    },
    {
        path: '/buchung',
        name: 'buchung',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Booking


    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Data


    },
    {
        path: '/impressum',
        name: 'Impressum',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Imprint


    },
    {
        path: '/das-team',
        name: 'das-team',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: TeamE


    },
    {
        path: '/statistik',
        name: 'statistik',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: Statistics


    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
