<template>
  <v-footer color="#e8b567" padless>
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link.id"
        color="#656563"
        text
        rounded
        class="my-2"
      >
        <router-link :to="link.url">
          <span  style="color: black;">{{ link.name }}</span>
        </router-link>
      </v-btn>
    </v-row>
    <div class="text-center">
      <v-bottom-sheet max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <div class="contact-btn">
            <v-avatar color="#f5f5f5" width="53" height="53" v-bind="attrs" v-on="on">
              <v-icon>mdi-forum</v-icon>
            </v-avatar>
          </div>
        </template>
        <v-card width="300">
          <v-list two-line>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-phone </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title><a href="tel:+491755722650">0175 / 57 22 650</a></v-list-item-title>
                <v-list-item-subtitle>Mobile</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-action></v-list-item-action>
              <v-list-item-content>
                <v-list-item-title><a href="tel:+4933701366391">033701 / 36 63 91</a></v-list-item-title>
                <v-list-item-subtitle>Festnetz</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-email </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title><a href="mailto:info@cct-handschuh.de">info@cct-handschuh.de</a></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
              <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-email </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                 <v-list-item-title class="contact"><a href="/contact">Kontaktformular</a></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider inset></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="indigo"> mdi-email </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                 <v-list-item-title class="contact"><a href="/buchung">Buchungsanfrage</a></v-list-item-title>
                 <v-list-item-subtitle>Erlebnisworkshop</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-bottom-sheet>
    </div>
    <back-top color="#f5f5f5" :size="1.5" :slow="10"></back-top>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      links: [
        {
          name: "Home",
          url: "/",
          id: 1,
        },
        {
          name: "Datenschutz",
          url: "datenschutz",
          id: 2,
        },
        {
          name: "Kontakt",
          url: "/contact",
          id: 3,
        },
        {
          name: "Impressum",
          url: "/impressum",
          id: 4,
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
  color: black;
  &:visited {
    color: black;
  }
}
.contact{
  a{
    color: black;
  }
}
.contact-btn {
  position: fixed;
  right: 27px;
  z-index: 8;
  bottom: 15px;
  @media screen and (max-width: 720px) {
    right: 0.5%;
  }
}
</style>
