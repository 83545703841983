<template>
  <div class="Team-dev">
    <team></team>
  </div>
</template>

<script>
import Team from "@/components/Team.vue";

export default {
  name: "CoachingVue",
  components: {
    Team,
  },
};
</script>

<style scoped>
</style>