<template>
  <main>
    <p></p>
  <div class="table-s">
     <v-simple-table dark>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Page
            </th>
            <th class="text-left">
              Count
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
          >
            <td>home</td>
            <td>{{ home }}</td>
          </tr>
           <tr
          >
            <td>coaching</td>
            <td>{{ coaching }}</td>
          </tr>
           <tr
          >
            <td>Seminare</td>
            <td>{{ seminare }}</td>
          </tr>
           <tr
          >
            <td>Team-Entwicklung</td>
            <td>{{ teamE }}</td>
          </tr>
           <tr
          >
            <td>Burn-out</td>
            <td>{{ burn }}</td>
          </tr>
           <tr
          >
            <td>Kontakt</td>
            <td>{{ contact }}</td>
          </tr>
           <tr
          >
            <td>Team</td>
            <td>{{ team }}</td>
          </tr>
           <tr
          >
            <td>Erlebnisseminare</td>
            <td>{{ seminareE }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
    <div class="v-content__wrap">
      <div class="container"></div>
    </div>
  </main>
</template>

<script>
import countapi from "countapi-js";
export default {
  name: "ContactFrom",
  data() {
    return {
      home: "",
      coaching: "",
      seminare: "",
      erlebnis: "",
      burn: "",
      team: "",
      teamE: "",
      contact: "",
      seminareE: "",
    };
  },
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  mounted() {
    countapi.get("cct-handschuh.de", "coaching").then((result) => {
      this.coaching = result.value;
    });
    countapi.get("cct-handschuh.de", "team-entwicklung").then((result) => {
      this.teamE = result.value;
    });
    countapi.get("cct-handschuh.de", "das-team").then((result) => {
      this.team = result.value;
    });
    countapi.get("cct-handschuh.de", "seminare").then((result) => {
      this.seminare = result.value;
    });
    countapi.get("cct-handschuh.de", "burn-out").then((result) => {
      this.burn = result.value;
    });
    countapi.get("cct-handschuh.de", "contact").then((result) => {
      this.contact = result.value;
    });
    countapi.get("cct-handschuh.de", "home").then((result) => {
      this.home = result.value;
    });
     countapi.get("cct-handschuh.de", "erlebnisseminare").then((result) => {
      this.seminareE = result.value;
    });
  },
};
</script>

<style lang="scss" scoped>
main{
  display: flex;
  justify-content: center;
}
.table-s{
  margin: 20px;
  min-width: 700px;
  @media screen and (max-width: 770px) {
  min-width: unset;
  }   
}
h2,
h4 {
  width: 100%;
  text-align: center;
}

form {
  input,
  textarea {
    border: 1px solid black;
  }

  textarea {
    min-height: 200px;
  }
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

button[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

button[type="submit"]:hover {
  background-color: #45a049;
}

.container {
  min-height: 700px;
  border-radius: 5px;
  padding: 20px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 762px) {
    min-height: 900px;
  }
}

.col-25 {
  float: left;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
</style>