<template>
  <main class="v-content" id="home" data-booted="true">
    <div class="v-content__wrap">
      <div id="home2">
        <section id="feed">
          <div class="container">
            <v-row>
              <v-col class="d-flex child-flex" cols="12">
                <v-img
                  max-height="280"
                  height="300"
                  class="white--text"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  src="../assets/img/seminare-i.jpg"
                  aspect-ratio="1.7"
                >
                  <div class="col-md-6 offset-md-6 col-9 title-col">
                    <h1 class="display-1 font-weight-light">
                      „Wenn es dir nicht gefällt, wie die Dinge sind: Beweg
                      Dich! Du bist kein Baum.“
                    </h1>
                    <div class="subheading img text-uppercase mb-4">
                      Jim Rohn
                    </div>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </div>
        </section>
        <section class="container" id="about">
          <h2 class="subheading text-uppercase font-weight-medium mb-3">
            Juni 2023: Wir sind wieder mit unserem beliebten Workshop auf See !!
          </h2>
          <div class="booking-btn">
            <p>Stress mit Menschen im Beruf oder Privatleben?</p>
            <p>
              Sie ärgern sich über das Verhalten von Kollegen und Kolleginnen,
              Führungskräften, Bekannten, Menschen an der Kasse, im Auto ?
            </p>
            <p>
              Sie wollen sich im Umgang mit schwierigen Menschen oder in
              schwierigen Situationen anders verhalten?
            </p>
            <p>
              Wenn Ihnen das irgendwie bekannt vorkommt und Sie künftig anders
              mit diesen Situationen umgehen oder diese Situationen verstehen
              wollen, dann kommen Sie mit zu uns an Bord und werden Sie Teil der
              Crew.
            </p>
            <p>
              Ob als Einzelperson, im Team, als Paar, mit Freunden oder der
              Familie. Legen Sie mit uns ab und lernen sie die Gattung Homo
              sapiens mal von einer anderen Seite kennen.
            </p>
            <p>
              Erik Handschuh wird in entspannter Atmosphäre die Grundlagen
              menschlichen Verhaltens anhand von Beispielen erklären und
              selbstverständlich gern an Ihren konkreten Themen und
              Fragestellungen ausrichten. Zusätzlich werden mit Ihnen konkrete
              Handlungsoptionen entwickelt, so dass Sie neue Erkenntnisse und
              Fähigkeiten mit nach Hause nehmen, die genau zu Ihrer Situation
              passen.
            </p>
            <p>
              Das alles wird kombiniert mit tollen Segelerlebnissen und
              gemütlichen Abenden in kleinen Häfen, wo genug Zeit zum Austausch
              zur Verfügung steht.
            </p>
            <p>
              Wir sind vom 03.06. – 30.06.2023 mit einer Bavaria 38 in der
              südwestlichen Ostsee unterwegs und werden Häfen in der dänischen
              Südsee, in der Region der Schleimündung und rund um Fehmarn
              anlaufen.
            </p>
            <p>
              Wenn wir Ihr Interesse geweckt haben, melden Sie sich gern bei uns
              und seien Sie für 699,- €/ Person und Woche* mit dabei, wenn wir
              im Juni 2023 zu einem tollen Erlebnis in See stechen.
            </p>
            <p>
              Sie haben Fragen oder benötigen eine individuelle Absprache?
              Sprechen Sie uns einfach an.
            </p>
            <p>Wir freuen uns auf Sie!</p>
            <p>Ihre Crew von creative coaching & training</p>
            <p>
              *zzgl. der üblichen Bordkasse für die gemeinsame Verpflegung,
              Hafengebühren, Kraftstoff
            </p>
            <p>
              Buchungsanfragen können gern <br />
              hier gestellt werden
            </p>
            <v-btn color="blue-grey">
              <router-link to="/buchung"
                ><span style="color: white"
                  >Buchungsanfrage stellen</span
                ></router-link
              >
            </v-btn>
            <p></p>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import countapi from "countapi-js";
export default {
  name: "AboutMe",
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
    countapi.update("cct-handschuh.de", "erlebnisseminare", 1);
    this.toTop();
  },
};
</script>

<style scoped lang="scss">
.booking-btn {
  margin-top: 20px;
}
.date {
  font-weight: bold;
}
.zitat {
  margin-top: 30px;
  margin-bottom: 35px;
  font-style: italic;
}
.title-col {
  margin-top: 30px;
}
@media (max-width: 400px) {
  .subheading.img.text-uppercase {
    padding-bottom: 10px;
    font-size: 13px !important;
  }
}
</style>