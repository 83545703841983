<template>
  <main
    class="v-content"
    id="home"
    data-booted="true"

  >
    <div class="v-content__wrap">
      <div id="home2">
        <section id="feed">
          <div class="container">
            <v-row>
              <v-col class="d-flex child-flex" cols="12">
                <v-img
                  max-height="280"
                  height="300"
                  class="white--text"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  src="../assets/img/burn-out.jpg"
                  aspect-ratio="1.7"
                >
                  <div class="col-md-6 offset-md-6 col-9 title-col">
                    <h1 class="display-1 font-weight-light">
                      „Probleme kann man niemals mit derselben Denkweise lösen, durch die sie entstanden sind.“
                    </h1>
                    <div class="subheading img text-uppercase mb-4">
                      Albert Einstein
                    </div>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </div>
        </section>
        <section class="container" id="about">
          <h2 class="subheading text-uppercase font-weight-medium mb-3">
            Burn-out
          </h2>
          <p>
            Die Diagnose Burn-Out ist in den letzten Jahren bei Mitarbeitenden
            und Führungskräften stark angestiegen. Nach Schätzungen von Experten
            sind allein in Deutschland bis zu 9 Millionen Menschen vom Burnout
            betroffen. Der volkswirtschaftliche Schaden wird auf 6,5 – 7,5
            Milliarden Euro jährlich geschätzt. Die Kosten für die westlichen
            Industriegesellschaften liegen bei 3-5% des jährlichen
            Bruttoinlandproduktes
          </p>
          <p>
            Aber nicht nur einzelne Personen können „ausbrennen“. Seit einigen
            Jahren spricht man immer häufiger auch von „ausgebrannten“ Teams.
            Ganze Unternehmen können „ausbrennen“ und damit weit hinter ihrer
            eigentlichen Leistungsfähigkeit bleiben.
          </p>
          <p>
            Glücklicherweise lassen sich die Faktoren, die maßgeblich das Risiko
            von Burn-Out fördern oder mindern, aktiv beeinflussen, unabhängig
            davon, ob es sich um einzelne Personen, Teams oder ein Unternehmen
            handelt. Gleichzeitig gibt es im Vorfeld immer Anzeichen, die ein
            rechtzeitiges Erkennen und Gegensteuern möglich machen. Wir ermitteln
            mit Ihnen die aktuelle Situation, entwickeln gemeinsam individuelle
            Lösungsansätze und begleiten die Umsetzung.
          </p>
          <p>
            Wenn Sie eine Frage haben, eine Anfrage stellen oder einen Termin
            vereinbaren möchten, rufen Sie gern an oder nutzen Sie das
            <router-link to="/contact"
              ><span style="text-decoration: underline"
                >Kontaktformular.</span
              ></router-link
            >
            Ich freue mich auf Sie.
          </p>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import countapi from 'countapi-js';
export default {
  name: "Burn-Out",
  methods: {
    toTop: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  },
  created() {
     countapi.update('cct-handschuh.de', 'burn-out', 1);
    this.toTop();
  },
};
</script>

<style scoped lang="scss">
.title-col {
  margin-top: 30px;
}
@media (max-width: 400px) {
  .subheading.img.text-uppercase {
    padding-bottom: 10px;
    font-size: 13px !important;
  }
}
</style>